import React from 'react'

function about() {
  return (
    <div>
      Page Under Construction. Come Back Later!
    </div>
  )
}

export default about;
