import './index.css';
import React from 'react';

import main from '../images/main.png';
import appliance from '../images/appliance.png';
import appliance3 from '../images/appliance3.png';
import appliance4 from '../images/appliance4.png';
import appliance5 from '../images/appliance5.png';
import appliance6 from '../images/appliance6.png';
import appliances from '../images/appliances.jpg';

import service from "../images/service.png";
import fridge from "../images/fridge.png";
import relationships from "../images/relationships.png";

import reyesappliance from "../images/reyes-appliance.jpg";
import reyesappliance1 from "../images/reyes-appliance1.jpg";

import Contact from '../Contact/contact';

const info = [
    {
        image: appliance,
        title: "Refrigerators",
        content: 'We ensure your refrigerator runs efficiently, providing a reliable foundation for everyday convenience and freshness'
    },

    {
        image: appliance3,
        title: "Stove",
        content: "We ensure your stove operates seamlessly and efficiently, fostering a delightful culinary experience"
    },
    {
        image: appliance4,
        title: "Washer",
        content: "We ensure your washing machine operates seamlessly and efficiently, allowing you to enjoy a consistently clean and well-organized home."
    },
    {
        image: appliance5,
        title: "Dryer",
        content: "We ensure your dryer operates seamlessly and efficiently, providing consistently dried clothes for a well-maintained home"
    },
    {
        image: appliance6,
        title: "Dishwasher",
        content: "We ensure your dishwasher stays effortlessly clean and efficient, fostering a hygienic and harmonious home environment"
    },
    {
        image: appliance3,
        title: "Oven",
        content: "We ensure your kitchen runs smoothly by maintaining your oven in top-notch condition, guaranteeing a delightful culinary experience with every use."
    },
];

const info_middle = [
    {
        image: service,
        title: "A Service You Can Trust",
        content: "At Reyes Appliance Repair, our team comprises licensed technicians with extensive expertise in appliance repair. You can trust us to bring a wealth of knowledge to every job, ensuring efficient and effective solutions for your appliances."
    },
    {
        image: fridge,
        title: "Appliance Care",
        content: "Whether it's your kitchen essentials or laundry appliances, we cover a wide range of devices. Our comprehensive service ensures that all your appliance repair needs are met under one roof, saving you time and providing a reliable solution for every situation."
    },
    {
        image: relationships,
        title: "Personal Relationships",
        content: "We prioritize a personal touch in our service. Our commitment to careful handling and transparent communication sets us apart, providing a level of care that goes beyond the standard repair experience."
    }

];

function Home() {
    return (
        <div className='homePage'>
            <section className="upper-header">
                <div className='title-info'>
                    <img className="img-title" src={appliances} alt="appliance pic" />
                    <div className="title-card">
                        <div className="title-top">
                            Home Appliances Broken? <br /> Let Us Help
                        </div>
                        <div className="title-intro">
                            Elevate your home's efficiency with our family-owned brooklyn-based appliance repair service. <br />
                            From fridges to ovens repair, we blend professionalism and reliability,
                            offering honest solutions for a smoothly running household
                            – because your comfort is our business!
                        </div>
                        <a href="tel:347-256-0951" className="anchor"> <button className="contact"> Call Us </button> </a>
                        <a href="mailto:reyesappliancerepairs@gmail.com" className="anchor"><button className="learn-more"> Email Us</button> </a>
                    </div>
                </div>
            </section>
            <section className="middle-page">
                <div className="middle-Inner">
                    <div className="left-content">
                        <div className="left-text">Appliance Repair Services in Brooklyn & NYC Boroughs
                        </div>
                        <p>
                            Household appliances quietly champion our daily comfort, efficiently managing essential tasks to elevate our quality of life. However, we often underestimate the toll of wear and tear on these indispensable devices, only realizing their impact when they malfunction.
                            Imagine your refrigerator losing its cooling prowess, or your stove's heating function taking an unscheduled break.
                        </p>
                        <p>
                            Turn to the expertise of our family-owned appliance repair service. Serving Brooklyn and all NYC boroughs, we are here to transform disruptions into efficient solutions, ensuring your household appliances work seamlessly once again.
                        </p>
                        <a href="tel:347-256-0951" className="anchor"><button className="middle-button">Call (347)-256-0951</button></a>
                    </div>

                    <div className="right-content">
                        <img className="middle-img" src={main} alt="refrigerator-washing-stove-appliance" />
                    </div>
                </div>
                <div className="top-middle">
                    <div className="top-text">
                        <h1>Our Diagnostic Fee Is <span>$64.99 </span></h1>
                        <h3>*Which Can Go Towards Repair*</h3>
                    </div>
                    <div className="top-text right">
                        <h1><span>Major Brands </span>Covered </h1>
                        <h1><span>Same/Next Day</span> Appointments</h1>
                        <h3>We Service: <span>Brooklyn, Queens, Bronx, Staten Island and Manhattan!</span> <br />
                            Monday - Friday: 8AM-7PM <br />
                            Saturday: 9AM-5PM <br />
                            Sunday: Closed
                        </h3>
                    </div>
                </div>
                <div className="middle-about">
                    <div className="middle-aboutUpper">
                        <div className="content">
                            <h1>We'll Come To You - Don't Worry</h1>
                            <p>Experience top-notch appliance repair services at great prices, without ever compromising on quality.
                                Our team of skilled professionals in New York City has got your back for any repair needs.
                                Count on us to handle things efficiently and effectively, so you can get back to your routine hassle-free.
                                With years of experience and top-notch tools, we'll diagnose and fix the issue with precision and speed.
                                Rest easy knowing your appliances are in good hands—we're here to keep things running smoothly for you.
                            </p>
                        </div>
                        <div className="about-content">
                            <img src={reyesappliance} alt="appliance-repair"></img>
                            <img src={reyesappliance1} alt="refrigator-repair"></img>
                        </div>
                    </div>
                    <div className="middle-aboutUs">
                        {info_middle.map(result => (
                            <div className='card-content'>
                                <img className="card-image" src={result.image} alt="services" />
                                <div className="card-upper">
                                    <h3>{result.title}</h3>
                                    <p>{result.content}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="bottom-middle">
                    <div className="bottom-text">
                        <a href="tel:347-256-0951" className="anchor"> <button className="contact bottom"> Call Us </button> </a>
                        <h1>
                            Don't Stress, Let Us Do The Work
                        </h1>
                    </div>
                </div>
            </section>
            <section className="bottom-page">
                <div className="top-bottom-page">
                    <p><span>WE SPECIALIZE IN A VARIETY OF APPLIANCES</span></p>
                    <h4 className="card-title">Residential Appliance Repairs & Services</h4>
                    <div className="cards">
                        {info.map(result => (
                            <div className="cards-content">
                                <img className="image-card" src={result.image} alt="image" />
                                <div className="upper-card">
                                    <h3>{result.title}</h3>
                                    {result.content}
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
                
            </section>
        </div>
    );
};

export default Home;